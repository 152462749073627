.main {
    text-align: center;
}

.user-list {
    width: 29em;
    margin: auto;
    list-style-type: none;
    justify-content: center;
}

.button-v2 {
    margin-left: 1em;
    font-family: 'Courier New', Courier, monospace;
    width: 6em;
    font-size: large;
    cursor: pointer;
    background-color: aliceblue;
    text-decoration: none;
    padding: 8px;
    display: inline-block;
    border-radius: 8px;
    transition-duration: 0.4s;
    color: #373737;
}

.button-v2:hover {
    border: 2px solid #3c3b3b;
    background-color: #555555;
    color: aliceblue;
}