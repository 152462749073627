.main {
    margin-top: 4em; /* Same as the width of the sidebar */
    padding: 0px 10px;
}

.account-keys {
    width: 47em;
    margin: auto;
    display: flex;
    justify-content: space-between;
}