body {
    background-color: #373737;
}

.main-div {
    margin-top: 10%;
    font-family: 'Courier New', Courier, monospace;
    background-color: #373737;
}

.login-form {
    text-align: center;
    font-family: 'Courier New', Courier, monospace;
}

.input {
    font-family: 'Courier New', Courier, monospace;
    margin: 0 0 10px 0;
    width: 30%;
    text-align: center;
    border: 2px solid lightgrey;
    font-size: x-large;
    padding-top: 5px;
    outline: none;
    border-radius: 5px;
    background-color: aliceblue;
    color: #373737;
}

.input:focus {
    border: 2px solid #555
}

.input:hover {
    border: 2px solid #555
}

.header-container {
    text-align: center;
    padding-top: 10px;
    font-size: 3em;
    color: aliceblue;
}

.button {
    font-family: 'Courier New', Courier, monospace;
    margin-bottom: 5px;
    margin-top: 5px;
    width: 30%;
    font-size: x-large;
    cursor: pointer;
    background-color: aliceblue;
    text-decoration: none;
    padding: 8px;
    display: inline-block;
    border-radius: 8px;
    transition-duration: 0.3s;
    color: #373737;
}

.button:hover {
    border: 2px solid #3c3b3b;
    background-color: #555555;
    color: aliceblue;
}