body {
    background-color: #373737;
}

.main {
    margin-top: 3em; /* Same as the width of the sidebar */
    padding: 0px 10px;
    background-color: #373737;
    color: #f0f8ff
}

.info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 0 20px;
    height: 40px;
}