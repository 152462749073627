.navbar {
    height: 3em; /* Full-height: remove this if you want "auto" height */
    width: 100%; /* Set the width of the sidebar */
    position: fixed; /* Fixed Sidebar (stay in place on scroll) */
    z-index: 1; /* Stay on top */
    top: 0; /* Stay at the top */
    left: 0;
    background-color: #111; /* Black */
    overflow-x: hidden; /* Disable horizontal scroll */
    /*padding-top: 20px;*/
    display: flex;
    justify-content: space-between;
}

.navbar button {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 20px;
    color: #818181;
    display: block;
    background-color: #111;
    border: 0;
}

.navbar button:hover {
    color: #f1f1f1;
    cursor: pointer;
}

.time-div {
    display: flex;
    padding-right: 1em;
    background: #111;
}

.date {
    background: #111;
    padding-right: 8px;
    color: #818181;
}

.time {
    background: #111;
    color: #818181;
}

.icon-style:hover {
    fill: #f1f1f1;
    cursor: pointer;
}