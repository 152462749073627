.date-container {
    position: relative;
    display: flex;
    margin: auto;
    width: 32em;
    justify-content: space-between;
}

.date-label {
    margin-bottom: 5px;
}

.start-div {
    height: 4em;
    display: flex;
    flex-direction: column;
}

.start-time {
    outline: none;
    width: 13em;
    height: 2em;
    border-radius: 7px;
    border: 1px solid whitesmoke;
}

.start-time:focus {
    border: 1px solid rgb(53, 162, 235);
}
.start-time:hover {
    border: 1px solid rgb(53, 162, 235);
}

.date-button {
    height: 2.5em;
    width: 4em;
    margin-top: 1.5em;
    border-radius: 7px;
    background-color: #adadad;
    border: 1px solid #adadad;
    color: black;
}

.date-button:hover {
    color: whitesmoke;
    background-color: #373737;
    transition-duration: 0.4s;
}